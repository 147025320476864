.FiltersModal .form-switch {
  padding: 0px;
}

.FiltersModal .form-check {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.FiltersModal .form-check-label {
  display: block;
}

.FiltersModal .form-switch .form-check-input {
  margin-left: 0px;
}

.FiltersModal .form-check .form-check-input {
  float: none;
  margin-left: 0px;
  margin-top: 18px;
}