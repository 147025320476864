.Header {
  height: 56px;
  margin: 0px var(--com-link-side-margin);
}

.Header img.congaLogo {
  height: inherit;
}

.Header button.loginButton {
  all: unset;
}

.Header span {
  cursor: pointer;
  font-size: 18px;
}
